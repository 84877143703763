import config from "./config";

export async function getToken() {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
        "username": "corpoca"
    });

    const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw
    };

    try {
        const response = await fetch(`${config.server}/getToken`, requestOptions);
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        const result = await response.json();
        return result.token;
    } catch (error) {
        console.error(error);
    }
}

export async function makeLogin(Correo, Contrasena) {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
        "Correo": Correo,
        "Telefono": Contrasena
    });

    const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw
    };

    try {
        const response = await fetch(`${config.server}/login`, requestOptions);
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        const result = await response.json();
        return result;
    } catch (error) {
        console.error(error);
    }
}


export async function getLogo(token = config.token) {
    let logo = "";
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", token);

    const requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow"
    };

    try {
        const response = await fetch(`${config.server}/get_logo`, requestOptions);
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        const result = await response.text();
        logo = result;
        return logo;
    } catch (error) {
        console.error(error);
    }
}

export async function updateLogo(token = config.token, logo) {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", token);
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
        url: logo,
    });

    const requestOptions = {
        method: "PUT",
        headers: myHeaders,
        body: raw,
        redirect: "follow"
    };

    try {
        const response = await fetch(`${config.server}/update_logo`, requestOptions);
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        const result = await response.text();
        console.log(result);
    } catch (error) {
        console.error(error);
    }
}

export async function getSlogan(token = config.token) {
    let slogan = "";
    const myHeaders = new Headers();
    myHeaders.append("Authorization", token);

    const requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow"
    };

    try {
        const response = await fetch(`${config.server}/get_slogan`, requestOptions);
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        const result = await response.text();
        slogan = result;
        return slogan;
    } catch (error) {
        console.error(error);
    }
}

export async function updateSlogan(token = config.token, slogan) {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", token);
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
        slogan: slogan,
    });

    const requestOptions = {
        method: "PUT",
        headers: myHeaders,
        body: raw,
        redirect: "follow"
    };

    fetch(`${config.server}/update_slogan`, requestOptions)
        .then((response) => response.text())
        .then((result) => console.log(result))
        .catch((error) => console.error(error));
}

export async function upload(file) {
    let url = "https://nuevo.automatadg.com/api/upload";

    let fileName = file.name;

    const formData = new FormData();
    formData.append("file", file);

    let newURL = "https://nuevo.automatadg.com/uploads/" + fileName;

    const requestOptions = {
        method: "POST",
        body: formData,
        redirect: "follow"
    };

    try {
        const response = await fetch(url, requestOptions);
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        return newURL;
    } catch (error) {
        console.error(error);
    }

}

export async function getCourses(token = config.token){
    const myHeaders = new Headers();
    myHeaders.append("Authorization", token);

    const requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow"
    };

    try {
        const response = await fetch(`${config.server}/get_courses`, requestOptions);
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        const result = await response.json();
        return result;
    } catch (error) {
        console.error(error);
    }
}


export async function getCourse(token = config.token, id){
    const myHeaders = new Headers();
    myHeaders.append("Authorization", token);

    const requestOptions = {
        method: "GET",
        headers: myHeaders,
    };

    try {
        const response = await fetch(`${config.server}/get_course/${id}`, requestOptions);
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        const result = await response.json();
        return result;
    } catch (error) {
        console.error(error);
    }
}

export async function updateCourse(token = config.token, course){
    let response = {};
    const myHeaders = new Headers();
    myHeaders.append("Authorization", token);
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify(course);

    const requestOptions = {
        method: "PUT",
        headers: myHeaders,
        body: raw,
        redirect: "follow"
    };

    fetch(`${config.server}/update_course/${course.id}`, requestOptions)
        .then((response) => response.text())
        .then((result) => {response = result; console.log(result); })
        .catch((error) => console.error(error));

    return response;
}

export async function deleteCourse(token = config.token, id){
    const myHeaders = new Headers();
    myHeaders.append("Authorization", token);

    const requestOptions = {
        method: "DELETE",
        headers: myHeaders,
        redirect: "follow"
    };

    let response = {};

    fetch(`${config.server}/delete_course/${id}`, requestOptions)
        .then((response) => response.text())
        .then((result) => { response = result; })
        .catch((error) => console.error(error));
    
    return response;
}

export async function createCourse(token = config.token, course){
    const myHeaders = new Headers();
    myHeaders.append("Authorization", token);
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify(course);

    const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow"
    };

    fetch(`${config.server}/create_course`, requestOptions)
        .then((response) => response.text())
        .then((result) => console.log(result))
        .catch((error) => console.error(error));
}

export async function getStudents(token = config.token){
    const myHeaders = new Headers();
    myHeaders.append("Authorization", token);

    const requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow"
    };

    try {
        const response = await fetch(`${config.server}/get_all_data`, requestOptions);
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        const result = await response.json();
        return result;
    } catch (error) {
        console.error(error);
    }
}

export async function getStudent(token = config.token, id){
    const myHeaders = new Headers();
    myHeaders.append("Authorization", token);

    const requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow"
    };

    try {
        const response = await fetch(`${config.server}/get_data/${id}`, requestOptions);
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        const result = await response.json();
        return result;
    } catch (error) {
        console.error(error);
    }
}

export async function createStudent(student, token = config.token){
    const myHeaders = new Headers();
    myHeaders.append("Authorization", token);
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify(student);

    const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow"
    };

    try {
        const response = await fetch(`${config.server}/save_data`, requestOptions);
        const result = await response.json();
        return result;
    } catch (error) {
        console.error(error);
    }
}

export async function updateStudent(token = config.token, student){

    const myHeaders = new Headers();
    myHeaders.append("Authorization", token);
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify(student);

    const requestOptions = {
        method: "PUT",
        headers: myHeaders,
        body: raw,
        redirect: "follow"
    };

    try {
        const response = await fetch(`${config.server}/update_data/${student.id}`, requestOptions);
        const result = await response.json();
        return result;
    } catch (error) {
        console.error(error);
    }
}

export async function deleteStudent(token = config.token, id){
    const myHeaders = new Headers();
    myHeaders.append("Authorization", token);

    const requestOptions = {
        method: "DELETE",
        headers: myHeaders,
        redirect: "follow"
    };

    let response = {};

    fetch(`${config.server}/delete_data/${id}`, requestOptions)
        .then((response) => response.text())
        .then((result) => { response = result; })
        .catch((error) => console.error(error));
    
    return response;
}

export async function marcarAsistencia(token = config.token, id_curso, id_estudiante){
    const Usuario = id_estudiante;
    const Curso = id_curso;

    const myHeaders = new Headers();
    myHeaders.append("Authorization", token);
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({ Usuario, Curso });

    const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow"
    };

    try {
        const response = await fetch(`${config.server}/asistencia`, requestOptions);
        const result = await response.json();
        return result;
    } catch (error) {
        console.error(error);
    }
}