import React, { useState } from 'react';

import { getCourses, createStudent } from './Api';

import { Container, Row, Col, Form, Dropdown, Image, Placeholder, Card, FloatingLabel, Button, Alert } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  const [correo, setCorreo] = useState('');
  const [telefono, setTelefono] = useState('');
  const [nombre, setNombre] = useState('');
  const [colegiado, setColegiado] = useState('');
  const [titulo, setTitulo] = useState('');
  const [textoSeleccion, setTextoSeleccion] = useState('Selecciona un curso');
  const [curso, setCurso] = useState({});
  const [cursos, setCursos] = useState([]);
  const [buttonEnabled, setButtonEnabled] = useState(true);
  const [guardadoExitoso, setGuardadoExitoso] = useState(false);

  React.useEffect(() => {
    getCourses().then((data) => {
      if (data) {
        let fechaHoy = new Date();
        let cursosParaInscripcion = data.filter((curso) => {
          let fechaInicio = new Date(curso.Fecha_Inicio_Inscripcion);
          let fechaFin = new Date(curso.Fecha_Fin_Inscripcion);

          fechaInicio.setHours(0, 0, 0, 0);
          fechaFin.setHours(23, 59, 59, 999);

          return fechaHoy >= fechaInicio && fechaHoy <= fechaFin;
        });
        setCursos(cursosParaInscripcion);
      }
    });
  }, []);

  React.useEffect(() => {
    // Retiramos espacios innecesarios del correo, además de convertirlo a minúsculas
    setCorreo(correo.replace(/\s/g, '').toLowerCase());
  }, [correo]);

  React.useEffect(() => {
    // Retiramos espacios innecesarios del teléfono
    setTelefono(telefono.replace(/\s/g, ''));
  }, [telefono]);

  const handleCursoChange = (curso) => {
    setTextoSeleccion(curso.Nombre);
    setCurso(curso);
    setButtonEnabled(false);
  };

  const handleImage = () => {
    if (curso.Banner) {
      return <Image src={curso.Banner} fluid />
    } else {
      return <Placeholder style={{ height: '350px', width: '100%' }} />
    }
  };

  const GuardadoExitoso = () => {
    return (
      <Alert variant="success" dismissible="true">
        <Alert.Heading>¡Guardado Exitoso!</Alert.Heading>
        <p>
          Tu inscripción ha sido guardada exitosamente.
        </p>
      </Alert>
    );
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let fechaActual = new Date();
    // formato de fecha: 2021-09-01T00:00:00.000Z
    fechaActual.setHours(0, 0, 0, 0);
    fechaActual = fechaActual.toISOString();

    let Estudiante = {
      Nombre: nombre,
      Correo: correo,
      Telefono: telefono,
      Colegiado: colegiado,
      Titulo: titulo,
      Curso: curso.key,
    };

    createStudent(Estudiante).then((data) => {
      if (data) {
        setCorreo('');
        setTelefono('');
        setNombre('');
        setColegiado('');
        setTitulo('');
        setTextoSeleccion('Selecciona un curso');
        setCurso({});
        setButtonEnabled(true);
        setGuardadoExitoso(true);
      }
    });

  };

  return (
    <Container>
      <Row className='justify-content-md-center'>
        <Col md={4} className='mt-4 mb-4'>
          <Dropdown>
            <Dropdown.Toggle variant="success" id="dropdown-basic">
              {textoSeleccion}
            </Dropdown.Toggle>

            <Dropdown.Menu>
              {cursos.map((curso) => {
                return <Dropdown.Item key={curso.key} onClick={() => handleCursoChange(curso)}>{curso.Nombre}</Dropdown.Item>
              })}
            </Dropdown.Menu>
          </Dropdown>
          <hr />
          {handleImage()}
        </Col>
        <Col md={5} className='mt-4 mb-4'>
          <Card>
            <Card.Header>
              <h3>Formulario de Inscripción</h3>
            </Card.Header>
            <Card.Body>
              <Form onSubmit={handleSubmit}>
                <Row>
                  <Col className='mb-2'>
                    <FloatingLabel controlId="floatingInput" label="Nombre">
                      <Form.Control type="text" placeholder="Nombre" value={nombre} onChange={(e) => setNombre(e.target.value)} required/>
                    </FloatingLabel>
                  </Col>
                </Row>

                <Row>
                  <Col className='mb-2'>
                    <FloatingLabel controlId="floatingInput" label="Correo">
                      <Form.Control type="email" placeholder="Correo" value={correo} onChange={(e) => setCorreo(e.target.value)} required/>
                    </FloatingLabel>
                  </Col>
                </Row>

                <Row>
                  <Col className='mb-2'>
                    <FloatingLabel controlId="floatingInput" label="Teléfono">
                      <Form.Control type="text" placeholder="Teléfono" value={telefono} onChange={(e) => setTelefono(e.target.value)} required/>
                    </FloatingLabel>
                  </Col>
                </Row>

                <Row>
                  <Col className='mb-2'>
                    <FloatingLabel controlId="floatingInput" label="Colegiado">
                      <Form.Control type="text" placeholder="Colegiado" value={colegiado} onChange={(e) => setColegiado(e.target.value)} required />
                    </FloatingLabel>
                  </Col>
                </Row>

                <Row>
                  <Col className='mb-2'>
                    <FloatingLabel controlId="floatingInput" label="Título">
                      <Form.Control type="text" placeholder="Ing. / Lic." value={titulo} onChange={(e) => setTitulo(e.target.value)} required />
                    </FloatingLabel>
                  </Col>
                </Row>
                <hr />
                <Row>
                  <Col className='text-end'>
                    <Button variant="primary" type="submit" disabled={buttonEnabled}>
                      Inscribirme
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row className='justify-content-md-center'>
        <Col md={9} className='mt-4 mb-4'>
          {guardadoExitoso ? <GuardadoExitoso /> : null}
        </Col>
      </Row>
    </Container>
  );
}

export default App;
